exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blastomere-biopsy-micropipette-js": () => import("./../../../src/pages/blastomere-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-blastomere-biopsy-micropipette-js" */),
  "component---src-pages-blog-comp-1-js": () => import("./../../../src/pages/blogComp1.js" /* webpackChunkName: "component---src-pages-blog-comp-1-js" */),
  "component---src-pages-blog-comp-2-js": () => import("./../../../src/pages/blogComp2.js" /* webpackChunkName: "component---src-pages-blog-comp-2-js" */),
  "component---src-pages-blog-comp-tag-js": () => import("./../../../src/pages/blogCompTag.js" /* webpackChunkName: "component---src-pages-blog-comp-tag-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-comp-faq-js": () => import("./../../../src/pages/comp-faq.js" /* webpackChunkName: "component---src-pages-comp-faq-js" */),
  "component---src-pages-customer-support-js": () => import("./../../../src/pages/customer-support.js" /* webpackChunkName: "component---src-pages-customer-support-js" */),
  "component---src-pages-frequently-asked-questions-js": () => import("./../../../src/pages/frequently-asked-questions.js" /* webpackChunkName: "component---src-pages-frequently-asked-questions-js" */),
  "component---src-pages-holding-micropipette-js": () => import("./../../../src/pages/holding-micropipette.js" /* webpackChunkName: "component---src-pages-holding-micropipette-js" */),
  "component---src-pages-icsi-js": () => import("./../../../src/pages/icsi.js" /* webpackChunkName: "component---src-pages-icsi-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-injection-micropipette-js": () => import("./../../../src/pages/injection-micropipette.js" /* webpackChunkName: "component---src-pages-injection-micropipette-js" */),
  "component---src-pages-micropipette-manufacturer-js": () => import("./../../../src/pages/micropipette-manufacturer.js" /* webpackChunkName: "component---src-pages-micropipette-manufacturer-js" */),
  "component---src-pages-news-comp-1-js": () => import("./../../../src/pages/newsComp1.js" /* webpackChunkName: "component---src-pages-news-comp-1-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-polar-body-biopsy-micropipette-js": () => import("./../../../src/pages/polar-body-biopsy-micropipette.js" /* webpackChunkName: "component---src-pages-polar-body-biopsy-micropipette-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quality-pledge-js": () => import("./../../../src/pages/quality-pledge.js" /* webpackChunkName: "component---src-pages-quality-pledge-js" */),
  "component---src-pages-resources-js": () => import("./../../../src/pages/resources.js" /* webpackChunkName: "component---src-pages-resources-js" */),
  "component---src-pages-trophectoderm-biopsy-micropipette-bevelled-js": () => import("./../../../src/pages/trophectoderm-biopsy-micropipette-bevelled.js" /* webpackChunkName: "component---src-pages-trophectoderm-biopsy-micropipette-bevelled-js" */),
  "component---src-pages-trophectoderm-biopsy-micropipette-flat-js": () => import("./../../../src/pages/trophectoderm-biopsy-micropipette-flat.js" /* webpackChunkName: "component---src-pages-trophectoderm-biopsy-micropipette-flat-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-frequently-asked-questions-js": () => import("./../../../src/templates/frequently-asked-questions.js" /* webpackChunkName: "component---src-templates-frequently-asked-questions-js" */),
  "component---src-templates-news-and-updates-js": () => import("./../../../src/templates/news-and-updates.js" /* webpackChunkName: "component---src-templates-news-and-updates-js" */)
}

